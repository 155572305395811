<script setup lang="ts">
import type { PostLayoutProps } from '@/types'

const { uri, seo } = defineProps<PostLayoutProps>()

const emit = defineEmits(['footerVisible'])

const footerId = useId()
const footerElement = ref(null)
const footerIsVisible = useElementVisibility(footerElement)

const headerElement = ref(null)
const headerIsVisible = useElementVisibility(headerElement)

watch(footerIsVisible, () => {
  emit('footerVisible', footerIsVisible.value)
})

const { isCrawler } = useDevice()

watch(headerIsVisible, () => {
  if (!isCrawler) {
    history.pushState({}, '', uri)

    definePageSeo(seo)
  }
})

const { data } = await useFetch('/api/legacy/post', {
  query: {
    slug: uri,
  },
})

useMassiveCrypto()
</script>

<template>
  <div class="mb-4">
    <article>
      <UContainer>
        <section class="flex flex-col lg:flex-row gap-4">
          <div class="flex-1 shadow-xl">
            <header
              ref="headerElement"
              class="relative group"
            >
              <PostHeaderImage
                v-if="data?.featured_media"
                :id="data.featured_media"
              />

              <div class="absolute top-0 inset-x-0 p-4 flex items-end overflow-hidden h-96">
                <div class="absolute inset-x-0 bottom-0 h-80 bg-gradient-to-t from-gray-900" />
                <div>
                  <!-- eslint-disable vue/no-v-html -->
                  <h1
                    class="relative text-md sm:text-lg md:text-xl lg:text-2xl font-bold text-gray-100 group-hover:text-primary-400 transition text-pretty"
                    v-html="data.title"
                  />
                </div>
              </div>
            </header>
            <section class="pb-2 bg-gray-900">
              <div class="flex flex-col md:flex-row items-center px-4 gap-4">
                <div class="flex-1 flex flex-wrap gap-2">
                  <div>
                    <UBadge color="black">
                      <UIcon
                        name="i-heroicons-calendar"
                        class="-mt-1px size-4 text-primary"
                      />
                      <span
                        v-if="data.date"
                        class="ml-1"
                      >{{ data.date.split('T')[0] }}</span>
                    </UBadge>
                  </div>
                  <PostCategories
                    :categories="data.categories"
                  />
                </div>
              </div>
            </section>

            <PostBanner v-if="data.acf?.disabledBannersHorizontal !== true" />

            <div class="p-4">
              <UPageBody prose>
                <PostBlocks
                  v-if="data.blocksJSON"
                  :blocks="data.blocksJSON"
                />
                <PostContent
                  v-else
                  :content="data.content"
                />
              </UPageBody>

              <template v-if="data.acf?.orangebox?.enabled">
                <NuxtLink
                  :to="data.acf?.orangebox?.url"
                  target="_blank"
                  class="text-base lg:text-17px text-gray-100 bg-primary hover:opacity-90 transition rounded flex items-center p-4 gap-4"
                >
                  <UIcon
                    name="i-mingcute-exchange-bitcoin-line"
                    class="size-8"
                  />
                  <p>
                    {{ data.acf?.orangebox?.content }}
                  </p>
                </NuxtLink>
              </template>

              <PostTags
                v-if="data.tags"
                :tags="data.tags"
              />
            </div>

            <div
              :id="footerId"
              ref="footerElement"
            >
              <div class="p-4 flex justify-between">
                <div v-if="data.nav?.next">
                  <UButton
                    leading-icon="i-heroicons-backward"
                    variant="link"
                    :padded="false"
                    size="xl"
                    :to="data.nav.next.to"
                  >
                    Poprzedni
                  </UButton>
                </div>

                <div v-if="data.nav?.prev">
                  <UButton
                    trailing-icon="i-heroicons-forward"
                    variant="link"
                    :padded="false"
                    size="xl"
                    :to="data.nav.prev.to"
                  >
                    Następny
                  </UButton>
                </div>
              </div>

              <PostAuthor
                v-if="data?.author"
                :id="data.author"
              />

              <AppNewsletter />
            </div>
          </div>

          <template v-if="data.acf?.disabledBannersVertical !== true">
            <PostAside />
          </template>
          <template v-else>
            <aside class="flex flex-col gap-4 pt-1 min-w-[300px]" />
          </template>
        </section>
      </UContainer>
    </article>
  </div>
</template>
